import React from 'react';
import Lottie from 'lottie-react';
import RegisterUserAnimation from "../../../lib/JSON/RegisterUser.json";
import ResetPasswordAnimation from "../../../lib/JSON/ResetPassword.json";
import LoadingAnimation from "../../../lib/JSON/LoadingAnimation.json";
import ErrorLoadingAnimation from "../../../lib/JSON/ErrorLoadingAnimation.json";
import NoDataAnimation from "../../../lib/JSON/NoDataAnimation.json";
import SuccessAnimation from "../../../lib/JSON/Payment-success.json";
import FailedAnimation from "../../../lib/JSON/Payment-failed.json";

export const RegisterAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={RegisterUserAnimation}
                loop={true}
                autoplay={true}
            />
        </div>
    );
};

export const ResetAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={ResetPasswordAnimation}
                loop={true}
                autoplay={true}
            />
        </div>
    )
}

export const ShowLoadingAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={LoadingAnimation}
                loop={true}
                autoplay={true}
            />
        </div>
    )
}

export const ShowErrorLoadingAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={ErrorLoadingAnimation}
                loop={false}
                autoplay={true}
            />
        </div>
    )
}

export const ShowNoDataAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={NoDataAnimation}
                loop={true}
                autoplay={true}
            />
        </div>
    )
}

export const PaymentSuccessAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={SuccessAnimation}
                loop={false}
                autoplay={true}
            />
        </div>
    )
}

export const PaymentFailedAnimation = () => {
    return (
        <div>
            <Lottie
                animationData={FailedAnimation}
                loop={false}
                autoplay={true}
            />
        </div>
    )
}
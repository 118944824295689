import React from "react";
import { Input, CircularProgressBar } from "@ui";
/* import TotalBill from '@assets/total-bill.svg';
import TotalSale from '@assets/total-sale.svg';
import Circle1 from '@assets/mask-circle-1.svg';
import Circle2 from '@assets/mask-circle-2.svg';
import Circle3 from '@assets/mask-circle-3.svg'; */
import { GetAllBusinessUnit, GetUserDashboard, useSubscription } from "@lib";
import { ShowLoadingAnimation } from '@common';
import { useQuery } from "@tanstack/react-query";
import { Select, MenuItem } from "@mui/material";
import { useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import animationData from "@/lib/JSON/Warning.json";
// import DatePicker from 'antd/es/date-picker';
// import moment from 'moment';
import DashboardGridBox from "@/components/common/DashboardGridBox/DashboardGridBox";
// import ApexCharts from 'apexcharts';

// const { RangePicker }: any = DatePicker;

export default function HomePage() {
    // const chartRef = React.useRef(null);
    const { isSubscribed, setIsSubscribed } = useSubscription();
    const location = useLocation();
    const [dashboardLoading, setDashboardLoading] = React.useState(true);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = React.useState(Cookies.get('From_date') || currentDate);
    const [dateTo, setDateTo] = React.useState(Cookies.get('To_date') || currentDate);
    /*     const [date, setDate] = React.useState<any>("");
        const [dateRange, setDateRange] = React.useState<any>([]);
        const [showRangePicker, setShowRangePicker] = React.useState(false); */
    const [bussinessUnit, setBussinessUnit] = React.useState(Cookies.get('License_key') || "")
    /*     React.useEffect(() => {
            const options = {
                chart: {
                    type: 'line',
                    height: '350px',
                   
                },
                stroke: {
                    curve: 'straight',
                    width: 1,
                    colors: ['#EB008A'],
                },
                markers: {
                    size: 6,
                    colors: ['#EB008A'],
                    strokeColors: '#EB008A',
                    strokeWidth: 2,
                    hover: {
                        size: 7
                    }
                },
                grid: {
                    borderColor: '#E0E0E0',
                    row: {
                        colors: ['#F9F9F9', 'transparent'],
                        opacity: 0.5
                    }
                },
                series: [
                    {
                        name: '',
                        data: [1000, 3000, 2000, 5000, 6000, 4000, 5000, 4000, 6000, 8000, 7000, 6000, 8000],
                    },
                ],
                xaxis: {
                    categories: ['May 01', 'May 02', 'May 03', 'May 04', 'May 05', 'May 06', 'May 07', 'May 08', 'May 09', 'May 10', 'May 11', 'May 12', 'May 13'],
                    labels: {
                        style: {
                            colors: '#000',
                            fontSize: '12px',
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: '',
                        style: {
                            color: '#000',
                            fontSize: '14px',
                        }
                    },
                    labels: {
                        style: {
                            colors: '#000',
                            fontSize: '12px',
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    x: {
                        formatter: (val: any) => `Date: ${val}`
                    },
                    y: {
                        formatter: (val: any) => `Sales: $${val}`
                    }
                }
            };
            const chart = new ApexCharts(chartRef.current, options);
            chart.render();
            return () => {
                chart.destroy();
            };
        }, []); */

    /* const handleRangeClick = (date: string) => {
        if (date === "lastRange") {
            setDate("lastRange");
            setShowRangePicker(true);
        } else {
            setDate(date);
            setDateRange([])
            setShowRangePicker(false);
        }
    }; */

    const { data: bussinessData } = useQuery({
        queryKey: ['AllBussinessUnit'],
        queryFn: () => GetAllBusinessUnit(),
    })

    const { data: dashboardData, isSuccess } = useQuery({
        queryKey: ['dashboard', dateFrom, dateTo, bussinessUnit],
        queryFn: async () => GetUserDashboard({ datefrom: dateFrom, dateto: dateTo })
    })

    React.useEffect(() => {
        Cookies.remove("orNumber");
        Cookies.remove("subDate");
        Cookies.remove("totalAmount");
        if (isSuccess && dashboardData) {
            const subscriptionStatus = dashboardData?.SubscriptionTransactions?.Status;
            if (subscriptionStatus === "undefined" || subscriptionStatus === "Trial" || subscriptionStatus === "Activated" || !isSubscribed) {
                if (subscriptionStatus === "Trial" && !Cookies.get('isSubscriptionAlertActive')) {
                    setIsDialogOpen(true);
                    Cookies.set('isSubscriptionAlertActive', "true");
                }
                setIsSubscribed(true);
                setDashboardLoading(false);
            } else {
                setIsSubscribed(false);
                setDashboardLoading(false);
            }
        }
    }, [isSuccess, dashboardData]);

    React.useEffect(() => {
        const isDashboardRoute = location.pathname === '/dashboard';
        if (isDashboardRoute && isDialogOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isDialogOpen, location.pathname]);

    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
        Cookies.set('From_date', event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
        Cookies.set('To_date', event.target.value as string);
    };
    const handleBussinessUnit = (event: { target: { value: any; }; }) => {
        const selectedValue = event.target.value;
        setBussinessUnit(selectedValue);
        Cookies.set('License_key', selectedValue);
    };
    const handleClose = () => {
        setIsDialogOpen(false);
    };
    return (
        <>
            {
                dashboardLoading ?
                    <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div> :
                    <div className="px-4 py-6 mb-2 h-screen bg-bottom bg-cover bg-no-repeat">
                        <div className={`${isDialogOpen ? "blur-sm" : ""}`}>
                            <div className="py-4 items-center rounded-xl drop-shadow-xl">
                                <div className={`flex flex-col lg:flex-row gap-2 items-center`}>
                                    <div className="grid w-fit self-start">
                                        <span>Bussiness Unit</span>
                                        <Select
                                            value={bussinessUnit}
                                            onChange={handleBussinessUnit}
                                            defaultValue={Cookies.get('License_key') || ""}
                                            className="h-8 w-56 rounded-[8px] shadow-xl outline-none"
                                            displayEmpty
                                        >
                                            <MenuItem value={""}>All</MenuItem>
                                            {bussinessData?.data
                                                ?.slice()
                                                .sort((a: { BUS_Name: string; }, b: { BUS_Name: any; }) => a.BUS_Name.localeCompare(b.BUS_Name))
                                                .map((unit: any, index: number) => (
                                                    <MenuItem key={index} value={unit.BUS_LicenceKey}>
                                                        {unit.BUS_Name}({unit.BUS_LicenceKey})
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </div>
                                    <div className={`flex flex-row gap-2 justify-end w-full`}>
                                        <div className="grid w-fit">
                                            <span>From</span>
                                            <Input variant={"calender"} className="w-full" defaultValue={dateFrom} type="date" onChange={handleFromDate} />
                                        </div>
                                        <div className="grid w-fit">
                                            <span>To</span>
                                            <Input variant={"calender"} defaultValue={dateTo} type="date" onChange={handleToDate} />
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className="flex justify-evenly py-5 flex-wrap gap-4">
                                <CircularProgressBar salesProgressBar title={"Total No. of Bills"} saleValue={dashboardData?.TotalBills || "0"} value={dashboardData?.TotalBills || "0"} />
                                <CircularProgressBar salesProgressBar title={"Gross Amount"} saleValue={dashboardData?.TotalGrossAmount || "0"} value={dashboardData?.TotalGrossAmount || "0"} />
                                <CircularProgressBar salesProgressBar title={"Tax"} saleValue={dashboardData?.TaxAmount || "0"} value={dashboardData?.TaxAmount || "0"} />
                                <CircularProgressBar salesProgressBar title={"Total"} saleValue={dashboardData?.TotalSaleAmount || "0"} value={dashboardData?.TotalSaleAmount || "0"} />
                            </div>
                            {/* <div className="py-5">
                    <div className="flex justify-between flex-col sm:flex-row items-center">
                        <h2 className="text-xl font-thin text-[#8B8B8B]">Report Graph</h2>
                        <div className="flex justify-between flex-col-reverse lg:flex-row items-center gap-5">
                            {showRangePicker &&
                                <RangePicker
                                    value={dateRange ? dateRange.map((date: any) => moment(date)) : []}
                                    onChange={(newDates: any) => setDateRange(newDates ? newDates.map((date: any) => moment(date).format('YYYY-MM-DD')) : [])}
                                />
                            }
                            <select value={date} className="outline-none focus:outline-none border-[1px] rounded-[8px] px-2 py-1 shadow-xl" onChange={(e) => handleRangeClick(e.target.value)}>
                                <option value="lastWeek">Last Week</option>
                                <option value="lastMonth">Last Month</option>
                                <option value="last3Month">Last 3 Month</option>
                                <option value="last6Month">Last 6 Month</option>
                                <option value="lastYear">Last Year</option>
                                <option value="lastRange">Custom Date</option>
                            </select>
                            <Select
                                className="h-8 w-32 rounded-[8px]"
                                value={"Last 7 days"}
                            >
                                <MenuItem value={"lastWeek"} onClick={() => handleRangeClick('lastWeek')}>Last Week</MenuItem>
                                <MenuItem value={"lastMonth"} onClick={() => handleRangeClick('lastMonth')}>Last Month</MenuItem>
                                <MenuItem value={"lastMonth"} onClick={() => handleRangeClick('lastMonth')}>Last 3 Month</MenuItem>
                                <MenuItem value={"lastMonth"} onClick={() => handleRangeClick('lastMonth')}>Last 6 Month</MenuItem>
                                <MenuItem value={"lastYear"} onClick={() => handleRangeClick('lastYear')}>Last Year</MenuItem>
                                <MenuItem value={"lastRange"} onClick={() => setShowRangePicker(!showRangePicker)}>Custom Date</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <hr className="border-[0.5px] border-[#0068CF] my-3" />
                    <div className="overflow-x-auto">
                        <div id="chart" ref={chartRef} className="w-full mt-10" />

                    </div>
                </div> */}

                            <section className="px-5">
                                <h2 className="text-xl font-thin text-[#8B8B8B]">Transaction Summary</h2>
                                <hr className="border-[0.5px] border-[#0068CF] my-3" />

                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 py-5">
                                    <DashboardGridBox number={dashboardData?.TotalBills || "0"} color="bg-[#FEEEF1]" title="No.of bills" />
                                    <DashboardGridBox number={dashboardData?.TotalGrossAmount || "0"} color="bg-[#FDEDC9]" title="Gross Amount" />
                                    <DashboardGridBox number={dashboardData?.TaxAmount || "0"} color="bg-[#D9FAE7]" title="Tax Amount" />
                                    <DashboardGridBox number={dashboardData?.TotalSaleAmount || "0"} color="bg-[#FFBBC6]" title="Total Amount" />
                                    <DashboardGridBox number={dashboardData?.CashTransactions || "0"} color="bg-[#C9CFDE]" title="Cash Transaction" />
                                    <DashboardGridBox number={dashboardData?.CardTransactions || "0"} color="bg-[#E0D1FF]" title="Card Transaction" />
                                    <DashboardGridBox number={dashboardData?.CreditTransactions || "0"} color="bg-[#ABF1FF]" title="Credit Transaction" />
                                    <DashboardGridBox number={dashboardData?.UPITransactions || "0"} color="bg-[#DA75FE]" title="UPI" />
                                    <DashboardGridBox number={dashboardData?.MixedTransactions || "0"} color="bg-[#e9a447]" title="Mixed Transactions" />
                                </div>
                            </section>
                        </div>
                        {isDialogOpen && (
                            <>
                                <div className="overlay" onClick={handleClose} />
                                <div className="absolute inset-0 flex items-center justify-center w-full z-50">
                                    <dialog
                                        className="w-full md:w-[50%] h-[300px] m-auto text-center px-20 p-5 rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
                                        open
                                    >
                                        <div className="w-28 m-auto">
                                            <Lottie
                                                animationData={animationData}
                                                loop={false}
                                                autoplay={true}
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                        Your account is on a trial version. You can subscribe to a plan or continue using the trial until {dashboardData?.SubscriptionTransactions?.SUT_ExpiredOn}
                                        <div className="mt-5 flex gap-5 justify-center">
                                            <button onClick={handleClose} className="bg-[#0070B4] p-3 text-white px-5 rounded-xl">Continue</button>
                                            <button onClick={handleClose} className="bg-[#FF5555] p-3 text-white px-5 rounded-xl">Cancel</button>
                                        </div>
                                    </dialog>
                                </div>
                            </>
                        )}
                    </div >
            }
        </>
    )
}
import React from "react";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../Routes";
import Lottie from 'lottie-react';
import animationData from "../lib/JSON/404.json";

const PageNotFound = () => {
    return (
        <section className="flex items-center h-full py-8 px-16 dark:bg-gray-900 dark:text-gray-100">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                        height={200} width={200}
                    />
                    <h2 className="my-2 font-extrabold text-6xl dark:text-gray-600">
                        <span className="sr-only">Error</span>404
                    </h2>
                    <p className="text-2xl font-semibold md:text-3xl">
                        Sorry, we couldn't find this page.
                    </p>
                    <p className="mt-4 mb-8 dark:text-gray-400">
                        But dont worry, you can find plenty of other things on our homepage.
                    </p>
                    <div>
                        <Link to={RouteNavigation.login} rel="noopener noreferrer">
                            <span className="brk-btn">Back to homepage</span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageNotFound;

import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface CircularProgressBarProps {
    title: string;
    saleValue: number;
    salesProgressBar: boolean;
}

function CircularProgressBar({ title, saleValue, salesProgressBar }: CircularProgressBarProps) {
    const [value, setValue] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            if (value < saleValue) {
                setValue(value + 1);
            }
        }, 30);
    }, [value, saleValue]);
    return (
        <div className='w-40'>
            <CircularProgressbarWithChildren strokeWidth={5} value={value} maxValue={100}
                styles={buildStyles({
                    pathColor: salesProgressBar ? `rgba(14, 94, 240, ${value / 100})` : `rgb(0, 104, 207, ${value / 100})`,
                })}

                className='animate-pulse shadow-xl rounded-full bg-transparent'
            >
                <div className='bg-transparent text-xs pb-4'>{title}</div>
                <span className='text-2xl font-semibold'>{saleValue}</span>
            </CircularProgressbarWithChildren>
        </div >

    );
}
export { CircularProgressBar };
import React from "react"
import { AxiosError } from "axios";
import { Input, Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Button } from "@ui";
import Pagination from '@mui/material/Pagination';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Excel from "@assets/excel.png";
import { useNavbarContext, GetEmployeeWiseSalesList } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation, ShowErrorLoadingAnimation, ShowNoDataAnimation } from '@common';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { Default } from "@/lib/constant";

const EmployeeWiseSale = () => {
    const { isNavbarOpen } = useNavbarContext();
    const [pageIndex, setPageIndex] = React.useState(1);
    const [startLetter, setStartLetter] = React.useState("");
    const currentDate = new Date().toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = React.useState(Cookies.get('From_date') || currentDate);
    const [dateTo, setDateTo] = React.useState(Cookies.get('To_date') || currentDate);
    const { isPending, isError, data, error, isFetching } = useQuery({
        queryKey: ['EmployeeSalesList', pageIndex, dateFrom, dateTo, startLetter],
        queryFn: () => GetEmployeeWiseSalesList({ index: pageIndex, size: Default.pageSize, datefrom: dateFrom, dateto: dateTo, startLetter: startLetter }),
    })
    const handleChange = (_event: any, value: React.SetStateAction<number>) => {
        setPageIndex(value);
    };
    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
        setPageIndex(1)
        Cookies.set('From_date', event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
        setPageIndex(1)
        Cookies.set('To_date', event.target.value as string);
    };
    const handleDownloadExcel = async () => {
        if (!(Default.pageSize * data?.data?.TotalPages)) {
            toast.error('No data to download', {
                position: "top-right",
                closeOnClick: true,
            });
            return;
        }
        const dataResponse = await GetEmployeeWiseSalesList({ index: pageIndex, size: Default.pageSize * data?.data?.TotalPages, datefrom: dateFrom, dateto: dateTo });
        if (dataResponse.status === 200) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Employee Sales');
            worksheet.columns = [
                { header: 'Employee', key: 'SAL_SalePersonName', width: 30 },
                { header: 'Amount', key: 'TotalAmount', width: 20 },
            ];
            dataResponse?.data?.SaleList?.forEach((item: any) => {
                worksheet.addRow({
                    SAL_SalePersonName: item.SAL_SalePersonName,
                    TotalAmount: item.TotalAmount.toFixed(2),
                });
            });
            worksheet.getColumn('SAL_SalePersonName').eachCell(cell => {
                cell.alignment = { horizontal: 'left' };
            });
            worksheet.getColumn('TotalAmount').eachCell(cell => {
                cell.alignment = { horizontal: 'right' };
            });
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'Employee Sales List.xlsx');
            toast.success('File Downloaded', {
                position: "top-right",
                closeOnClick: true,
            });
        } else {
            toast.error('Download Failed ' + dataResponse.error || '', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    };
    return (
        <div className="px-2">
            <div className="py-4 items-center rounded-xl drop-shadow-xl">
                <div className={`flex flex-row gap-2 justify-end items-center`}>
                    <div className="grid w-fit">
                        <span>From</span>
                        <Input variant={"calender"} className="w-full" defaultValue={dateFrom} type="date" onChange={handleFromDate} />
                    </div>
                    <div className="grid w-fit">
                        <span>To</span>
                        <Input variant={"calender"} defaultValue={dateTo} type="date" onChange={handleToDate} />
                    </div>
                </div>
            </div >

            <div className="flex flex-col lg:flex-row md:justify-between">
                <h2 className="text-xl font-semibold pb-2">Employee Wise Sales</h2>
                <div className="flex flex-col sm:flex-row gap-2">
                    <Button onClick={handleDownloadExcel} variant={"download"} className="flex gap-2 outline outline-1 outline-gray-200">
                        <img src={Excel} alt="excel" />
                        Export
                    </Button>
                    <Input className="w-full sm:w-[240px] h-full" onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setStartLetter(e.target.value)} placeholder="Search" />
                </div>
            </div>
            <section className="py-2 relative mb-5">
                <div className="overflow-auto">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Sl.No</TableHead>
                                <TableHead>
                                    <span className="flex gap-2 items-center">
                                        Employee
                                    </span>
                                </TableHead>
                                <TableHead>
                                    <span className="flex gap-2 items-center justify-end">
                                        Amount
                                    </span>
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {!isError && data?.data?.SaleList.map((sale: any, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{sale.SAL_SalePersonName}</TableCell>
                                        <TableCell className="text-right">{parseFloat(sale.TotalAmount).toFixed(2)}</TableCell>
                                    </TableRow>
                                )
                            })
                            }
                        </TableBody>
                    </Table>
                </div>
                <div className="relative mb-10">
                    {!isPending && !isFetching && !isError && !data?.data?.SaleList?.length && <div className="w-64 m-auto text-center"><ShowNoDataAnimation /></div>}
                    {isPending && <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div>}
                    {!isError && data?.data?.SaleList?.length ? <div className="flex justify-end">
                        <Pagination count={data?.data?.TotalPages} page={pageIndex} onChange={handleChange} size="medium" />
                    </div> : null}
                    {!isPending && isFetching && <div className="w-32 m-auto text-center absolute top-[50%] left-[50%]"><ShowLoadingAnimation /></div>}
                    {!isPending && !isFetching && isError && (
                        <div className="w-32 m-auto font-bold text-center absolute top-[50%] left-[50%]">
                            <div><ShowErrorLoadingAnimation /></div>
                            <div>
                                {
                                    (error instanceof AxiosError) ? error.response?.data?.Message : 'An error occurred'
                                }
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${isNavbarOpen ? "sm:pl-[19rem]" : ""} w-full bg-white transition-all duration-300 delay-0 flex p-5 gap-2  fixed left-0 right-0 bottom-0`}>
                    <div className=" text-sm border border-gray-200 border-1 bg-white rounded-full shadow-xl p-2">
                        No of employee : {data?.data?.totalItemCountSum}
                    </div>
                    <div className="text-sm border border-gray-200 border-1 bg-blue-600 text-white rounded-full shadow-xl p-2 fixed right-2">
                        Total : {data?.data?.totalAmountSum}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EmployeeWiseSale;
import "./App.css";
import React from "react";
import GenericRoutes from "./Routes";
import { NavbarProvider, AuthProvider, SubscriptionProvider } from '@lib';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <NavbarProvider>
          <div className="font-poppins">
            <GenericRoutes />
          </div>
          <ToastContainer />
        </NavbarProvider>
      </SubscriptionProvider>
    </AuthProvider>
  );
}

export default App;

import React from "react";
import { PaymentFailedAnimation } from "@/components/common";

const PaymentFailed = () => {
    const handleLogout = () => {
        
    }
    return (
        <div className="bg-gray-100 h-screen">
            <div className="p-6  md:mx-auto">
                <div className="flex justify-center w-64 m-auto">
                    <PaymentFailedAnimation />
                </div>
                <div className="text-center">
                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Failed!</h3>
                    <p className="text-gray-600 my-2">We apologize for the inconvenience. Please try again!.</p>
                    <button onClick={handleLogout} className="px-12 bg-blue-600 hover:bg-blue-500 rounded-[8px] text-white font-semibold py-3">
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PaymentFailed;
import React from "react"
import { Input, Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Button } from "@ui";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Excel from "@assets/excel.png";
import { GetUserDashboard } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import Cookies from "js-cookie";

const ListOfSales = () => {
    const pageSize = 10;
    const currentDate = new Date().toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = React.useState(Cookies.get('From_date') || currentDate);
    const [dateTo, setDateTo] = React.useState(Cookies.get('To_date') || currentDate);
    const bussinessUnit = React.useState(Cookies.get('License_key') || "")

    const { data } = useQuery({
        queryKey: ['dashboard', dateFrom, dateTo, bussinessUnit],
        queryFn: async () => GetUserDashboard({ datefrom: dateFrom, dateto: dateTo })
    })
    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
        Cookies.set('From_date', event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
        Cookies.set('To_date', event.target.value as string);
    };
    const TotalPages = data?.data?.TotalPages;
    const TotalResponse = pageSize * TotalPages;
    const handleDownloadExcel = async () => {
        const dataResponse = await GetUserDashboard({ size: TotalResponse, datefrom: dateFrom, dateto: dateTo, bussinessUnit });
        if (dataResponse) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sales List');
            worksheet.columns = [
                { header: 'Particulars', key: 'SAL_Particular', width: 30 },
                { header: 'Amount', key: 'SAL_BillAmount', width: 20 },
            ];
            worksheet.addRow({
                SAL_Particular: 'Sales in Cash',
                SAL_BillAmount: dataResponse?.TotalCashSale
            });
            worksheet.addRow({
                SAL_Particular: 'Sales in Credit',
                SAL_BillAmount: dataResponse?.TotalCreditSale
            });
            worksheet.addRow({
                SAL_Particular: 'Sales in Card',
                SAL_BillAmount: dataResponse?.TotalCardSale
            });
            worksheet.addRow({
                SAL_Particular: 'Sales in Wallet',
                SAL_BillAmount: dataResponse?.TotalUPISale
            });
            const totalRow = worksheet.addRow({
                SAL_Particular: 'Total',
                SAL_BillAmount: dataResponse?.TotalSaleAmount
            });

            totalRow.getCell(1).font = { bold: true };
            totalRow.getCell(2).font = { bold: true };

            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'Day End Report.xlsx');
            toast.success('File Downloaded', {
                position: "top-right",
                closeOnClick: true,
            });
        } else {
            toast.error('Download Failed ' + dataResponse.error || '', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    };
    return (
        <div className="px-2">
            <div className="py-4 items-center rounded-xl drop-shadow-xl">
                <div className="flex flex-row gap-2 items-center justify-center md:justify-end">
                    <div className="grid w-fit">
                        <span>From</span>
                        <Input variant={"calender"} className="w-full" defaultValue={dateFrom} type="date" onChange={handleFromDate} />
                    </div>
                    <div className="grid w-fit">
                        <span>To</span>
                        <Input variant={"calender"} defaultValue={dateTo} type="date" onChange={handleToDate} />
                    </div>
                </div>
            </div >

            <div className="flex flex-col lg:flex-row md:justify-between">
                <div><h2 className="text-xl font-semibold pb-2">Day End Report</h2></div>
                <div className="flex flex-col sm:flex-row gap-2 items-center">
                    <Button onClick={handleDownloadExcel} variant={"download"} className="flex gap-2 outline outline-1 outline-gray-200">
                        <img src={Excel} alt="excel" />
                        Export
                    </Button>
                </div>
            </div>
            <section className="py-2 mb-5">
                <div className="overflow-auto ">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="text-center border-r">Particulars</TableHead>
                                <TableHead className="text-center">Amount</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell className="border-r">Sales in Cash</TableCell>
                                <TableCell className="text-end">{data?.TotalCashSale || "0"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="border-r">Sales in Credit</TableCell>
                                <TableCell className="text-end">{data?.TotalCreditSale || "0"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="border-r">Sales in Card</TableCell>
                                <TableCell className="text-end">{data?.TotalCardSale || "0"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="border-r">Sales in Wallet</TableCell>
                                <TableCell className="text-end">{data?.TotalUPISale || "0"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-bold text-lg border-r">Total</TableCell>
                                <TableCell className="font-bold text-lg text-end">{data?.TotalSaleAmount || "0"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </section >
        </div >
    )
}

export default ListOfSales;
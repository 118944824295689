import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { UpdateAccountSubscription, GetUserDashboard } from '@/lib/api/api';
import { useSubscription } from "@lib";
import { UpdatePaymentResponse } from '@/lib/types/index';

interface RazorpayOptions {
  key: string;
  currency: string;
  name: string;
  description: string;
  image: string;
  order_id: string;
  handler: (response: any) => void;
  notes: {
    address: string;
  };
  theme: {
    color: string;
  };
}

declare global {
  interface Window {
    Razorpay: any;
  }
}

const loadRazorpayScript = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('razorpay-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.id = 'razorpay-script';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
    } else {
      resolve(true);
    }
  });
};

export const useOpenRazorpay = () => {
  const navigate = useNavigate();
  const { setIsSubscribed } = useSubscription();
  const openRazorpay = async (data: { amount: any, order_id: any, subscriptionPlan: any }) => {
    try {
      const scriptLoaded = await loadRazorpayScript();
      const currentDate = new Date().toISOString().split('T')[0];
      if (!scriptLoaded) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }
      const options: RazorpayOptions = {
        key: 'rzp_live_ZKPn8fA80E6lHQ',
        currency: 'INR',
        name: 'Xenia Technologies',
        description: 'Subscription Payment',
        image: 'https://cdn.pixabay.com/photo/2024/01/28/16/17/ai-generated-8537858_1280.png',
        order_id: data.order_id,
        handler: async function (response: any) {
          const updatePayment = {
            SUT_InvoiceDate: currentDate,
            SUT_SubAccUid: Cookies.get("userId"),
            SUT_PlanUid: data.subscriptionPlan.SUP_Uid,
            SUT_Days: data.subscriptionPlan.SUP_Days,
            SUT_MOP: "Online Payment",
            SUT_PaymentAmount: data.subscriptionPlan.SUP_Price,
            SUT_Currency: data.subscriptionPlan.SUP_Currency,
            SUT_PaymentRef: response.razorpay_payment_id,
            SUT_RzRequestOrderId: response.razorpay_order_id,
            SUT_RzOauth: response.razorpay_signature,
            SUT_Remarks: "Payment for subscription",
            SUT_ModifiedBy: Cookies.get("userType")
          };
          try {
            const dataResponse: UpdatePaymentResponse = await UpdateAccountSubscription(updatePayment);
            if (dataResponse.Status === "Success") {
              const fetchDashboardData = await GetUserDashboard({ datefrom: currentDate, dateto: currentDate });
              if (fetchDashboardData?.SubscriptionTransactions?.Status !== "Expired") {
                setIsSubscribed(true);
                Cookies.set("orNumber", response.razorpay_order_id);
                Cookies.set("subDate", currentDate);
                Cookies.set("totalAmount", updatePayment.SUT_PaymentAmount);
                navigate('/payment-success', { replace: true });
              } else (
                setIsSubscribed(false)
              )
            } else {
              console.error('Payment Failed :', dataResponse);
            }
          } catch (error) {
            console.error('Error occurred during payment update:', error);
          }
        },
        notes: {
          address: 'User Address',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response: any) {
        alert('Error: ' + response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.error("Error loading Razorpay script:", error);
    }
  };

  return openRazorpay;
};
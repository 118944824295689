import React from "react";

type GridBoxType = {
    number: string;
    color: string;
    title: string;
}

function DashboardGridBox({ number, color, title }: GridBoxType) {
    return (
        <div className={`${color} font-poppins rounded-[9px] p-5 pb-8 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]`}>
            <div className="font-extrabold text-xl">{number}</div>
            <span className="text-xs font-semibold">{title}</span>
        </div>
    )
}

export default DashboardGridBox;
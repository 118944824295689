import React, { useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useReactToPrint } from 'react-to-print';
import { PaymentSuccessAnimation } from "@/components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteNavigation } from "@/Routes";
import Cookies from 'js-cookie';

const PaymentSuccess = () => {
    const queryClient = useQueryClient();
    const componentRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const OrderNumber = Cookies.get("orNumber");
    const SubscriptionDate = Cookies.get("subDate");
    const TotalAmount = Cookies.get("totalAmount");
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (!OrderNumber || !SubscriptionDate || !TotalAmount) {
            navigate(RouteNavigation.dashboard, { replace: true });
        }
    }, [navigate, location.pathname, OrderNumber, SubscriptionDate, TotalAmount]);

    const handleRedirectToHome = () => {
        queryClient.invalidateQueries({ queryKey: ["AllBussinessUnit"] })
        queryClient.invalidateQueries({ queryKey: ["dashboard"] })
        navigate(RouteNavigation.dashboard, { replace: true });
    };
    return (
        <div className="max-w-md w-full bg-white rounded-lg shadow-2xl p-6 text-center m-auto">
            <div ref={componentRef} className="p-5">
                <div className="flex justify-center w-64 m-auto">
                    <PaymentSuccessAnimation />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 text-center">Payment successful</h2>
                <p className="text-gray-500 mt-2 text-center">Your order has been confirmed and is being processed</p>

                <div className="bg-gray-50 border border-gray-200 rounded-md mt-6 p-4">
                    <div className="flex justify-between text-gray-600 mb-2">
                        <span>Order number</span>
                        <span className="font-semibold">{OrderNumber}</span>
                    </div>
                    <div className="flex justify-between text-gray-600 mb-2">
                        <span>Date</span>
                        <span className="font-semibold">{SubscriptionDate}</span>
                    </div>
                    <div className="flex justify-between text-gray-600">
                        <span>Total</span>
                        <span className="font-semibold">Rs. {TotalAmount}</span>
                    </div>
                </div>
            </div>
            <div className="flex justify-center gap-4">
                <button
                    className="mt-6 bg-black text-white py-2 px-4 rounded-[5px] hover:bg-gray-800 transition"
                    onClick={handlePrint}
                >
                    Download as PDF
                </button>
                <button onClick={handleRedirectToHome} className="mt-6 bg-black text-white py-2 px-4 rounded-[5px] hover:bg-gray-800 transition">
                    Return to Home
                </button>
            </div>
        </div>
    )
}

export default PaymentSuccess;
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetUserDashboard, GetAccountProfile, CreatePayment, useSubscription } from "@/lib";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableHeader,
  TableBody
} from "@ui";
import Dashboard from "../../assets/dashboard.jpeg";
import { FiLogOut } from "react-icons/fi";
import { useOpenRazorpay } from "@/components/common/PaymentGateway";
import Lottie from "lottie-react";
import animationData from "@/lib/JSON/Subscription Expired.json";
import { useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import Cookies from "js-cookie";

const SubscriptionExpired = () => {
  const navigate = useNavigate();
  const { setIsSubscribed } = useSubscription();
  const currentDate = new Date().toISOString().split('T')[0];
  const openRazorpay = useOpenRazorpay();
  const { data: dashboardData } = useQuery({
    queryKey: ["GetUserDashboard"],
    queryFn: () => GetUserDashboard({ datefrom: currentDate, dateto: currentDate }),
  });

  useEffect(() => {
    if (dashboardData?.SubscriptionTransactions?.Status !== "Expired" || dashboardData?.SubscriptionTransactions?.Status !== undefined) {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }
  }, [dashboardData]);

  const { isError, data } = useQuery({
    queryKey: ["GetAccountProfile"],
    queryFn: () => GetAccountProfile(),
  });
  const handleClick = async (profile: any) => {
    const dataResponse = await CreatePayment({ orderId: profile.SUP_Uid, amount: profile.SUP_Price, currency: profile.SUP_Currency })
    if (dataResponse.status === 200) {
      openRazorpay({ amount: parseFloat(profile.SUP_Price), order_id: dataResponse?.data?.OrderId, subscriptionPlan: profile });
    }
  };

  const handleLogout = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    navigate(RouteNavigation.login);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <div className="relative">
      <img src={Dashboard} alt="dashboard" className="w-screen h-screen" />
      <div className="absolute inset-0 flex items-center justify-center w-full md:w-[75%] m-auto">
        <dialog
          className="w-full lg:w-[75%] m-auto text-center px-2 sm:px-10 p-5 rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
          open
        >
          <button onClick={handleLogout} className="absolute top-5 right-5 p-2 rounded-xl border-2 border-black bg-black text-white flex items-center gap-2">
            <FiLogOut />
            Log out
          </button>
          <div className="w-28 m-auto">
            <Lottie
              animationData={animationData}
              loop={false}
              autoplay={true}
              height={100}
              width={100}
            />
          </div>
          Account Expired Please Renew Subscription!
          <div className="py-2">
            <div className="overflow-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="text-center">Plan Name</TableHead>
                    <TableHead className="text-center">Plan Validity</TableHead>
                    <TableHead className="text-center">Amount</TableHead>
                    <TableHead className="text-center">Subscribe</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!isError &&
                    data?.data?.SubscriptionPackageList?.map(
                      (profile: any, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className="text-center p-2">
                              {profile.SUP_Name}
                            </TableCell>
                            <TableCell className="text-center p-2">
                              For {profile.SUP_Days} Days
                            </TableCell>
                            <TableCell className="text-center p-2 default_font">
                              {profile.SUP_Price} ₹{" "}
                            </TableCell>
                            <TableCell className="text-center p-2">
                              <button onClick={() => handleClick(profile)} className="bg-[#17A36F] py-2 px-4 rounded-[5px] text-white">
                                Pay
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
        </dialog>
      </div>
    </div>
  );
};
export default SubscriptionExpired;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@radix-ui/themes/styles.css";
import "antd/dist/reset.css";
import { Theme } from "@radix-ui/themes";
import { HashRouter } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Provide the client to your App
  <QueryClientProvider client={queryClient}>
    <HashRouter>
      <ScrollToTop />
      <Theme>
        <App />
      </Theme>
    </HashRouter>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);
